import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import minomatorigin from './images/minomat.png';
import minomat from './images/minomat light version.png';
import event from './images/axes/event.png';
import formation from './images/axes/formation.png';
import projet from './images/axes/projet.png';
import it from './images/services/it.png';
import consu from './images/services/consu.png';
import elect from './images/services/elect.png';
import meca from './images/services/meca.png';
import proficiency from './images/00adb5 on white.png';
import Navbar from './comp/Navbar';
import stalibi from './images/stalibi.svg';

function App() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [countdown, setCountdown] = useState("");
  const [counts, setCounts] = useState({ exposants: 0, participants: 0, editions: 0 });
  
  const bgs = [
    require('./images/forum/1.jpg'),
    require('./images/forum/2.jpg'),
    require('./images/forum/3.jpg'),
    require('./images/forum/4.jpg'),
  ];


  const buttonStyle = {
    width: '100%',
    padding: '0.75rem',
    backgroundColor: '#333',
    color: '#fff',
    fontSize: '1rem',
    fontWeight: '600',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  };

  buttonStyle[':hover'] = { backgroundColor: '#555' };

  useEffect(() => {
    const handleContextMenu = (e) => e.preventDefault();
    const handleKeyDown = (e) => {
      function ctrlShiftKey(e, keyCode) {
        return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
      }

      if (
        e.keyCode === 123 || // F12
        ctrlShiftKey(e, 'I') || // Ctrl + Shift + I
        ctrlShiftKey(e, 'J') || // Ctrl + Shift + J
        ctrlShiftKey(e, 'C') || // Ctrl + Shift + C
        (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) // Ctrl + U
      ) {
        e.preventDefault();
        return false;
      }
    };

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const counters = document.querySelectorAll(".stat-number");

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const counter = entry.target;
          const target = +counter.getAttribute("data-target");
          let count = 0;
          const increment = target / 100;

          const updateCount = () => {
            if (count < target) {
              count += increment;
              counter.innerText = Math.ceil(count);
              setTimeout(updateCount, 30);
            } else {
              counter.innerText = target;
            }
          };

          updateCount();
          counter.classList.add('fadeIn-visible');
          observer.unobserve(counter);
        }
      });
    }, { threshold: 0.3 });

    counters.forEach((counter) => {
      observer.observe(counter);
    });

    return () => {
      counters.forEach((counter) => {
        observer.unobserve(counter);
      });
    };
  }, []);

  useEffect(() => {
    const animateCountUp = (target, currentValue, setValue) => {
      const increment = Math.ceil(target / 100);
      if (currentValue < target) {
        const newValue = Math.min(currentValue + increment, target);
        setValue(newValue);
        setTimeout(() => animateCountUp(target, newValue, setValue), 30);
      }
    };
    animateCountUp(30, 0, (val) => setCounts((counts) => ({ ...counts, exposants: val })));
    animateCountUp(2000, 0, (val) => setCounts((counts) => ({ ...counts, participants: val })));
    animateCountUp(25, 0, (val) => setCounts((counts) => ({ ...counts, editions: val })));
  }, []);

  useEffect(() => {
    const targetDate = new Date(new Date().getFullYear(), 10, 20, 8, 30, 0).getTime();

    const countdownInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance < 0) {
        clearInterval(countdownInterval);
        setCountdown("À très bientôt!");
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setCountdown(`${days} J : ${hours} H : ${minutes} M : ${seconds} S`);
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % bgs.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [bgs.length]);

  const partnerImages = require.context('./images/entreprise', false, /\.(png|jpe?g|svg)$/);
  const images = Array.from({ length: 36 }, (_, index) => partnerImages(`./${index + 1}.png`));

  useEffect(() => {
    const typingElement = document.querySelector('.typing');

    const onAnimationEnd = (event) => {
      if (event.animationName === 'typing') {
        typingElement.classList.add('endCursor');
      }
    };

    typingElement.addEventListener('animationend', onAnimationEnd);

    return () => {
      typingElement.removeEventListener('animationend', onAnimationEnd);
    };
  }, []);

  useEffect(() => {
    const sections = document.querySelectorAll('.fadeIn');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fadeIn-visible');
          }
        });
      },
      { threshold: 0.2 }
    );

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  return (
    <div>
      <Navbar />
      <header
        id="home"
        className="text-white d-flex flex-column justify-content-center align-items-center header-background"
        style={{
          height: `100vh`,
          backgroundImage: `url(${bgs[currentImageIndex]})`,
          position: 'relative',
        }}
      >
        <div
          className="header-overlay"
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
            opacity: '0.4',
            zIndex: '1',
          }}
        ></div>

        <div className="text-container" style={{ position: 'relative', zIndex: '2' }}>
          <div className="top-head">
            <img className="minomat" src={minomat} alt="Mindset Over Matter" />

          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', position: 'absolute' }}>
            <div className="moving-h1">
              FORUM <div style={{ color: '#ffcd11' }}>ENIT</div> ENTREPRISE
            </div>
            <div className="moving-h1">
              FORUM <div style={{ color: '#ffcd11' }}>ENIT</div> ENTREPRISE
            </div>
            <div className="moving-h1">
              FORUM <div style={{ color: '#ffcd11' }}>ENIT</div> ENTREPRISE
            </div>
          </div>

          <div className="typing-container" style={{ zIndex: '2', position: 'absolute' }}>
            <p className="soustitre typing">
              Soyez les bienvenus le <div style={{ color: '#ffcd11' }}>20</div> novembre à l'<div style={{ color: '#ffcd11' }}>ENIT</div>.
            </p>
          </div>



          <div className="countdown">{countdown}</div>
        </div>
      </header>

      <section id="about" className="about-section py-5 fadeIn">
        <div className="container">
          <h2 className="text-center mb-4" style={{ fontWeight: 'bold', color: '#28384d' }}>Qui sommes-nous ?</h2>
          <div className="junior-text-block">
            <p> Depuis la fondation de <strong>l'ENIT Junior Entreprise</strong>, la première Junior Entreprise en Tunisie, les élèves de l'<strong>ENIT</strong> ont marqué une certaine <strong>valeur ajoutée</strong> auprès de l'<strong>écosystème entrepreneurial</strong> vu la diversité de leurs compétences, leur <strong>flexibilité</strong> et leur <strong>polyvalence</strong>. </p>
            <p><strong>L'ENIT Junior Entreprise</strong> est un organisme à but <strong>non lucratif</strong>. C'est une association à <strong>vocation pédagogique et économique</strong> et à <strong>caractère professionnel</strong>, incitant les étudiants à la <strong>vie professionnelle</strong> dès l'aube de leurs études.</p>
          </div>

          <h2 className="text-center mb-4" style={{ fontWeight: 'bold', color: '#28384d' }}>Nos axes</h2>
          <div className="logo-axes">
            <img className="projet" src={projet} alt="projet" />
            <img className="formation" src={formation} alt="formation" />
            <img className="event" src={event} alt="event" />
          </div>
          <h2 className="text-center mb-4" style={{ fontWeight: 'bold', color: '#28384d' }}>Nos prestations de service</h2>
          <div className="logos-service">
            <div className="service">
              <img className="consu" src={consu} alt="consu" />
              <p className="service-title">Consulting</p>
            </div>
            <div className="service">
              <img className="it" src={it} alt="it" />
              <p className="service-title">IT</p>
            </div>
            <div className="service">
              <img className="elect" src={elect} alt="elect" />
              <p className="service-title">Conception et étude électrique</p>
            </div>
            <div className="service">
              <img className="meca" src={meca} alt="meca" />
              <p className="service-title">Conception et étude mécanique</p>
            </div>
          </div>
        </div>
      </section>

      <section id="forum" className="forum-section  py-5 fadeIn" style={{ backgroundColor: '#f7f7f7', paddingTop: '60px', paddingBottom: '60px' }}>
        <div className="container" style={{ maxWidth: '900px', margin: '0 auto' }}>
          <h2 className="text-center mb-4" style={{ fontWeight: 'bold', color: '#28384d', fontSize: '2rem' }}>
            Forum <span className="text-center mb-4" style={{ fontWeight: 'bold', color: '#FCCD11' }}>ENIT</span> Entreprise
          </h2>
          <p style={{ fontSize: '1.15rem', color: '#333', lineHeight: '1.6', marginTop: '20px', textAlign: 'center', }}>
            L’<strong>ENIT</strong> organise annuellement le <strong>FORUM ENIT ENTREPRISE</strong> pour arriver à en souffler cette année sa <strong>26ème édition</strong>.
          </p>
          <img className="minomatoriginal" src={minomatorigin} alt="Forum Entreprise" style={{ width: '100%', marginTop: '20px' }} />
          <p style={{ fontSize: '1.125rem', color: '#333', lineHeight: '1.6', marginTop: '20px', textAlign: 'justify', textAlignLast: 'left' }}>
            Le <strong>Forum ENIT Entreprise</strong> a une double vocation : Il introduit les futurs ingénieurs au <strong>monde professionnel</strong> et offre aux entreprises une occasion unique de renforcer leur <strong>notoriété</strong> et de recruter des <strong>stagiaires</strong> et des candidats pour les <strong>projets de fin d'études</strong>.
            Ce forum accueille une diversité d'exposants, incluant des entreprises de divers secteurs économiques, des laboratoires, des unités de recherche, des clubs et des associations.
          </p>
          <div className="stats-section" style={{ padding: '40px 20px', textAlign: 'center' }}>
            <h3 style={{ fontWeight: 'bold', color: '#28384d', marginBottom: '30px', fontSize: '1.8rem' }}>
              Forum ENIT Entreprise en chiffres
            </h3>
            <div className="stats-container" style={{ display: 'flex', justifyContent: 'center', gap: '40px', flexWrap: 'wrap', flexDirection: 'row' }}>
              <div className="stat-item">
                <div className="ma3a"><p className="plus">+</p>      <p className="stat-number" data-target={30}>{counts.exposants}</p>
                </div>
                <p>Exposants</p>
              </div>
              <div className="stat-item">
                <div className="ma3a"><p className="plus">+</p>      <p className="stat-number" data-target={2000}>{counts.participants}</p>    </div>
                <p>Participants</p>
              </div>
              <div className="stat-item">
                <p className="stat-number" data-target={25}>{counts.editions}</p>
                <p>Éditions</p>
              </div>
            </div>
          </div>




          <a href="https://www.facebook.com/share/14dVu8z6Bq/" className="eventlink" target="_blank" rel="noopener noreferrer">
            Pour plus d'information cliquez ici
          </a>
        </div>
      </section>

      <section id="forum" className="partners-section py-5 fadeIn">
        <div className="container">
          <h2 className="text-center mb-4" style={{ fontWeight: 'bold', color: '#28384d' }}>CEUX QUI NOUS ONT FAIT CONFIANCE</h2>
          <div className="partners-carousel">
            <div className="partners-carousel-inner">
              {images.concat(images).map((imageSrc, index) => (
                <img key={index} src={imageSrc} alt={`Partner ${index + 1}`} />
              ))}
            </div>
          </div>
        </div>
      </section>

      <footer className="footer bg-dark text-white text-center py-4">
        <img className="proficiency" src={proficiency} alt="Proficiency is our currency" />
        <p>© 2024 ENIT Junior Entreprise</p>
        <p>
  <a href="mailto:contact@enitje.com" class="mailto-link">contact@enitje.com</a>
</p>


        <p>Suivez-nous sur les réseaux sociaux :</p>
        <div className="social-links">
          <button className="btn btn-link" onClick={() => window.open('https://www.facebook.com/juniorenit', '_blank')}>
            <FontAwesomeIcon icon={faFacebook} />
          </button>
          <button className="btn btn-link" onClick={() => window.open('https://www.instagram.com/enit.juniorentreprise/', '_blank')}>
            <FontAwesomeIcon icon={faInstagram} />
          </button>
          <button className="btn btn-link" onClick={() => window.open('https://www.linkedin.com/company/enitjuniorentreprise', '_blank')}>
            <FontAwesomeIcon icon={faLinkedin} />
          </button>
        </div>
        <div className="createur">
<img className="stalibi" src={stalibi} alt="Alibi Studio" />


</div>
      </footer>

    </div>
  );
}

export default App;
