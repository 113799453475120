import React, { useState, useRef, useEffect } from 'react';
import './Navbar.css';
import logo from '../images/Primary logo - Colored.png';

const Navbar = ({ setPopupOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [activeLink, setActiveLink] = useState('#home');
  const menuRef = useRef(null);
  const navbarRef = useRef(null);
  const lastScrollY = useRef(0);


  const toggleMenu = () => setIsMenuOpen(prev => !prev);
  const closeMenu = () => setIsMenuOpen(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) &&
          navbarRef.current && !navbarRef.current.contains(event.target) &&
          isMenuOpen) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMenuOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsScrolled(currentScrollY > 50);
      setIsVisible(currentScrollY <= lastScrollY.current || currentScrollY <= 50);
      lastScrollY.current = currentScrollY;

      // Update active link based on section in view
      const sections = ['#home', '#about', '#forum'];
      sections.forEach((section) => {
        const sectionElement = document.querySelector(section);
        if (sectionElement) {
          const rect = sectionElement.getBoundingClientRect();
          if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
            setActiveLink(section);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <nav
        className={`navbar ${isScrolled ? 'navbar-scrolled' : ''} ${isVisible ? '' : 'navbar-hidden'}`}
        ref={navbarRef}
      >
        <div className="navbar-content">
          <a href="/" className="logo">
            <img src={logo} alt="Logo" />
          </a>
          <ul className="nav-links">
            <li>
              <a href="#home" className={`nav-link ${activeLink === '#home' ? 'active' : ''}`}>
                Accueil
              </a>
            </li>
            <li>
              <a href="#about" className={`nav-link ${activeLink === '#about' ? 'active' : ''}`}>
                Qui sommes-nous?
              </a>
            </li>
            <li>
              <a href="#forum" className={`nav-link ${activeLink === '#forum' ? 'active' : ''}`}>
                Forum Entreprise
              </a>
            </li>
          </ul>

        </div>
  
        <div className="navwhendrop">
          <a href="/" className="logo_resp">
            <img src={logo} alt="Logo" />
          </a>
          <button
            className="navbar-toggle"
            onClick={toggleMenu}
            aria-label="Toggle menu"
            aria-expanded={isMenuOpen}
            aria-controls="menu"
          >
            {isMenuOpen ? '✖' : '☰'}
          </button>
          {isMenuOpen && (
            <div className="menu open" ref={menuRef}>
              <ul className="nav-links">
                <li>
                  <a
                    href="#home"
                    className={`nav-link ${activeLink === '#home' ? 'active' : ''}`}
                    onClick={closeMenu}
                  >
                    Accueil
                  </a>
                </li>
                <li>
                  <a
                    href="#about"
                    className={`nav-link ${activeLink === '#about' ? 'active' : ''}`}
                    onClick={closeMenu}
                  >
                    Qui sommes-nous?
                  </a>
                </li>
                <li>
                  <a
                    href="#forum"
                    className={`nav-link ${activeLink === '#forum' ? 'active' : ''}`}
                    onClick={closeMenu}
                  >
                    Forum Entreprise
                  </a>
                </li>

              </ul>
            </div>
          )}
        </div>
      </nav>
    </>
  );
  
};

export default Navbar;
